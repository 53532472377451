import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import backroundPattern from "../../img/pattern.svg";
import map from "../../img/map.svg";
import logo from "../../img/logo.svg";
import { Email } from "../../handleForm";

import styled, { css } from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

export const HomeContent = styled.div`
  /* position: absolute; */
  text-align: center;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 100vh;
  h1 {
    font-family: "Nexa";
    color: #dddbe2;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
`;
export const Home = styled.div`
  width: 100%;
  /* height: 100vh; */
  position: relative;
  background: white;
  z-index: 1;
  background: ${(props) => (props.color ? props.color : "#27213c")};
  /* Hide the original tooltips contents */
  .pin {
    display: none;
  }

  /* Begin styling the tooltips and pins */
  .tooltip-up,
  .tooltip-down {
    position: absolute;
    background: url(${logo});
    width: 25px;
    height: 37px;
  }

  .tooltip-down {
    background-position: 0 -37px;
  }

  .tooltip {
    display: none;
    width: 200px;
    cursor: help;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    top: 10px;
    left: 50%;
    z-index: 999;
    margin-left: -115px;
    padding: 15px;
    color: #222;
    border-radius: 5px;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.7);
    background: #fff1d3;
    background: linear-gradient(top, #fff1d3, #ffdb90);
  }

  .tooltip::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -10px;
    border-bottom: 10px solid #fff1d3;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  .tooltip-down .tooltip {
    bottom: 12px;
    top: auto;
  }

  .tooltip-down .tooltip::after {
    bottom: -10px;
    top: auto;
    border-bottom: 0;
    border-top: 10px solid #ffdb90;
  }

  .tooltip h2 {
    font: bold 1.3em "Trebuchet MS", Tahoma, Arial;
    margin: 0 0 10px;
  }

  .tooltip ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ${HomeContent} {
    padding-top: 200px;

    /* padding: 175px 42px 0; */

    ::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -99;

      background: url(${backroundPattern}),
        ${(props) => (props.color ? props.color : "#27213c")};
      background-size: 100%;
      /* background-repeat: no-repeat; */
      background-position-x: right;
      background-position-y: top;
      opacity: 1;
    }
  }
`;
export const ToolTip = styled.div`
  display: none;
  top: -800%;
  right: -600%;
  background: #9a1c1f;
  color: white;
  font-family: "Nexa";
  font-size: 12px;
  width: 250px;
  height: 100px;
  border-radius: 10px;
  border: 1px #9a1c1f solid;
  position: absolute;
  /* top: -25px; */
  z-index: 99;
  opacity: 0.6;
`;
export const TooltipWrap = styled.div`
  position: absolute;
  z-index: 99;
  background: url(${logo});
  width: 20px;
  height: 20px;
  background-size: 100%;
  :before {
    content: "";
    display: none;
    width: 0;
    height: 60px;
    position: absolute;
    border: 1px solid #9a1c1f;
    top: -60px;
    opacity: 0.6;

    /* left: 100px; */
  }
  :hover {
    ${ToolTip} {
      display: block;
      z-index: 99;
    }
    transform: scale(1.1);

    :before {
      display: unset;
    }
  }
`;
export const ImageMap = styled.div`
  position: relative;
  margin: 5px;

  /* height: 592px;
  width: 927px; */
  background: url(${map}) no-repeat center;
  z-index: 0;
  height: 100vh;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  & ${TooltipWrap}:nth-child(1) {
    /* height: 20px;
    width: 20px; */
    /* top: 48%;
    right: 44%; */
    /* background: yellow; */

    z-index: 99;
  }
  & ${TooltipWrap}:nth-child(2) {
    /* height: 20px;
    width: 20px; */
    top: 48%;
    right: 38%;
    /* background: pink; */

    z-index: 99;
  }
  & ${TooltipWrap}:nth-child(3) {
    /* height: 20px;
    width: 20px; */
    top: 49%;
    right: 36%;
    /* background: blue; */

    z-index: 99;
  }
`;

export const Box = styled.div`
  /* width: 50px;
  height: 100px; */

  position: absolute;

  top: 48%;
  right: 46%;
`;
const Form = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 30px;
  flex-grow: 4;
  min-width: 50vw;
  /* width: 100%; */
`;
const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #9a1c1f;
  font-size: 1rem;
  display: flex;
  width: 100%;
  /* width: 70vw; */
  color: #dddbe2;
  ::placeholder {
    color: #dddbe2;
  }
  :focus {
    outline: none;
  }
`;
const TextField = styled.textarea`
  background: transparent;
  border: none;
  border-bottom: 1px solid #9a1c1f;
  font-size: 1rem;
  color: #dddbe2;
  display: flex;
  width: 100%;
  ::placeholder {
    color: #dddbe2;
  }
  :focus {
    outline: none;
  }
`;
const Button = styled.button`
  height: 50px;
  font-size: 1rem;
  background: #9a1c1f;
  box-shadow: none;
  border: none;
  color: #dddbe2;
`;
const Label = styled.label`
  color: #dddbe2;
  text-align: left;
`;
const FormWrapper = styled.div`
  width: 60%;
  margin: auto;
  padding: 1rem;
`;
const Index = () => {
  React.useEffect(() => {
    AOS.init();
  });

  const [subject, setSubject] = React.useState("");
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const handleSubmit = (value) => {
    value.preventDefault();
    // console.log("value", value);
  };
  return (
    <Layout>
      <Home>
        <HomeContent>
          <h1>Contact Us </h1>
          <ImageMap
            data-aos="zoom-in"
            data-aos-offset="80"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
          >
            <Box>
              <TooltipWrap>
                <ToolTip class="tooltip">
                  <div>
                    <strong>EGYPT:</strong>
                    <span>
                      52 Mostafa nahas st. apt.101, nasr city, cairo, egypt,
                      11371
                    </span>
                    <br />
                    <span>
                      <strong>tel:</strong> +202-22718890/98
                    </span>
                    <span> +202-22754007</span>
                    <br />

                    <span>
                      <strong>fax:</strong> +202-2271-8900
                    </span>
                  </div>
                </ToolTip>
              </TooltipWrap>
            </Box>

            <TooltipWrap>
              <ToolTip class="tooltip">
                <div>
                  <strong>QATAR:</strong>
                  <span>
                    803, 8th Floor, Regency Office Business Center 2 Doha
                  </span>
                  <br />
                  <span>
                    <strong>tel:</strong> +974-4421-5675
                  </span>
                  <br />

                  <span>
                    <strong>fax:</strong>+974-4421-5696
                  </span>
                </div>
              </ToolTip>
            </TooltipWrap>
            <TooltipWrap>
              <ToolTip class="tooltip">
                <div>
                  <strong>DUBAI:</strong>
                  <span>
                    #S14, w09, Russia Center, International CIty- 01, PO Box.
                    116508
                  </span>
                  <br />
                  <span>
                    <strong>tel:</strong>+971-565-734-771{" "}
                  </span>{" "}
                </div>
              </ToolTip>
            </TooltipWrap>
          </ImageMap>
          <FormWrapper
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
          >
            <Form
              // onSubmit={handleSubmit}
              action={`mailto:Technical@miratechfire.com?subject=${subject}&body=name:%0A${name},%0Aphone:%0A${mobile}%0D%0A${message}`}
              method="post"
              enctype="text/plain"
            >
              <Label>
                Name
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                  }}
                />
              </Label>
              <Label>
                Email
                <Input type="email" name="email" id="email" />
              </Label>
              <Label>
                Mobile
                <Input
                  type="tel"
                  name="mobile"
                  id="mobile"
                  onChange={(e) => {
                    setMobile(e.currentTarget.value);
                  }}
                />
              </Label>
              <Label>
                Subject
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  onChange={(e) => {
                    setSubject(e.currentTarget.value);
                  }}
                />
              </Label>
              <Label>
                Message
                <TextField
                  name="message"
                  id="message"
                  rows="5"
                  onChange={(e) => {
                    setMessage(e.currentTarget.value);
                  }}
                />
              </Label>
              <Button type="submit">Send</Button>
              {/* <Input type="reset" value="Clear" /> */}
            </Form>
          </FormWrapper>
        </HomeContent>
      </Home>
    </Layout>
  );
};

export default Index;
